import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Casestudy from '../../containers/Casestudy';

const propTypes = {
  data: PropTypes.shape({
    contentfulCaseStudyDetail: PropTypes.shape({}).isRequired,
    contentfulCompanyQuote: PropTypes.shape({
      title: PropTypes.string,
      quote: PropTypes.string,
      footerDescription: PropTypes.shape({}).isRequired,
      icons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

const CasestudyPage = ({ data, location }) => {
  const { contentfulCaseStudyDetail: detail, contentfulCompanyQuote: companyQuote } = data;
  return <Casestudy detail={detail} location={location} companyQuote={companyQuote} />;
};

CasestudyPage.propTypes = propTypes;
export default CasestudyPage;

export const query = graphql`
  query($caseStudyUri: String!) {
    contentfulCaseStudyDetail(caseStudyUri: { eq: $caseStudyUri }) {
      caseStudyContent {
        caseStudyContent
      }
      caseStudyUri
      bannerTitle
      bannerButtonTitle
      headerBgColor
      tags
      referenceLink
      featuredImage {
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      fullImage {
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      fullImageCaption {
        fullImageCaption
      }
      fullImage2 {
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      introTitle1
      introDescription1 {
        introDescription1
      }
      introTitle2
      introDescription2 {
        introDescription2
      }
      introTitle3
      introDescription3 {
        introDescription3
      }
      bannerDescription {
        bannerDescription
      }
      caseStudyFeatures {
        backgroundColor
        description {
          description
        }
        imageSliders {
          id
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        title
        hideTitle
        viewType
      }
      servicesUsed {
        title
        services {
          name
          description
          icon
        }
      }
      testimonial {
        testimonial
      }
      workScope {
        id
        title
        workItem
      }
      listTitle
      caseStudyReferences {
        name
        description {
          description
        }
        brandLogo {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        productImage {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        moreInfoTitle
        caseStudyPage {
          caseStudyUri
        }
      }
    }
    contentfulCompanyQuote(label: { eq: "servicesAws" }) {
      title
      quote
      footerDescription {
        json
      }
    }
  }
`;
