import React from 'react';
import PropTypes from 'prop-types';
import s from './AWSServiceSection.module.scss';

const AWSServiceSection = ({ servicesData }) => {
  if (!servicesData || !servicesData.services) return null;

  return (
    <div className={s.serviceSection}>
      <div className={s.container} data-aos="fade-up">
        <h2>{servicesData.title}</h2>
        <div className={s.servicesGrid}>
          {servicesData.services.map((service) => (
            <div key={service.name} className={s.serviceCard}>
              <img src={service.icon} alt={service.name} className={s.serviceIcon} />
              <h4>{service.name}</h4>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

AWSServiceSection.propTypes = {
  servicesData: PropTypes.shape({
    title: PropTypes.string,
    services: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
        icon: PropTypes.string,
      }),
    ),
  }),
};

AWSServiceSection.defaultProps = {
  servicesData: {
    title: '',
    services: [],
  },
};

export default AWSServiceSection;
